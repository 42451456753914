import { ActionType, createAction, props } from "@ngrx/store";
import {
  AddLoanDataExtraction,
  DataExtractionHIL,
  DeleteRestoreFlagFieldValue,
  DocumentContentResponse,
  DocumentTypeResponse,
  FetchField,
  GetEntityList,
  GetFieldList,
  IDocumentType,
  IParentDocument,
  UpdateLoanDataExtraction,
} from "./data-extraction-hil.types";

export const LOAD_DATA_EXTRACTION_HIL = "LOAD_DATA_EXTRACTION_HIL";
export const LOAD_DATA_EXTRACTION_HIL_SUCCESS = "LOAD_DATA_EXTRACTION_HIL_SUCCESS";
export const LOAD_DATA_EXTRACTION_HIL_FAILURE = "LOAD_DATA_EXTRACTION_HIL_FAILURE";

export const ADD_LOAN_DATA_EXTRACTION = "ADD_LOAN_DATA_EXTRACTION";
export const ADD_LOAN_DATA_EXTRACTION_SUCCESS = "ADD_LOAN_DATA_EXTRACTION_SUCCESS";
export const ADD_LOAN_DATA_EXTRACTION_FAILURE = "ADD_LOAN_DATA_EXTRACTION_FAILURE";

export const UPDATE_LOAN_DATA_EXTRACTION = "UPDATE_LOAN_DATA_EXTRACTION";
export const UPDATE_LOAN_DATA_EXTRACTION_SUCCESS = "UPDATE_LOAN_DATA_EXTRACTION_SUCCESS";
export const UPDATE_LOAN_DATA_EXTRACTION_FAILURE = "UPDATE_LOAN_DATA_EXTRACTION_FAILURE";

export const DELETE_RESTORE_FIELD_VALUE = "DELETE_RESTORE_FIELD_VALUE";
export const DELETE_RESTORE_FIELD_VALUE_SUCCESS = "DELETE_RESTORE_FIELD_VALUE_SUCCESS";
export const DELETE_RESTORE_FIELD_VALUE_FAILURE = "DELETE_RESTORE_FIELD_VALUE_FAILURE";

export const GET_DOCUMENT_BY_DOCUMENT_ID = "GET_DOCUMENT_BY_DOCUMENT_ID";
export const GET_DOCUMENT_BY_DOCUMENT_ID_SUCCESS = "GET_DOCUMENT_BY_DOCUMENT_ID_SUCCESS";
export const GET_DOCUMENT_BY_DOCUMENT_ID_FAILURE = "GET_DOCUMENT_BY_DOCUMENT_ID_FAILURE";

export const UPDATE_IS_TRUTH = "UPDATE_IS_TRUTH";
export const UPDATE_IS_TRUTH_SUCCESS = "UPDATE_IS_TRUTH_SUCCESS";
export const UPDATE_IS_TRUTH_FAILURE = "UPDATE_IS_TRUTH_FAILURE";

export const FETCH_LOAN_FIELDS = "FETCH_LOAN_FIELDS";
export const FETCH_LOAN_FIELDS_SUCCESS = "FETCH_LOAN_FIELDS_SUCCESS";
export const FETCH_LOAN_FIELDS_FAILURE = "FETCH_LOAN_FIELDS_FAILURE";

export const GET_ENTITY_LIST = "GET_ENTITY_LIST";
export const GET_ENTITY_LIST_SUCCESS = "GET_ENTITY_LIST_SUCCESS";
export const GET_ENTITY_LIST_FAILURE = "GET_ENTITY_LIST_FAILURE";

export const UPDATE_THE_FIELD_DATA = "UPDATE_THE_FIELD_DATA";
export const UPDATE_THE_FIELD_DATA_SUCCESS = "UPDATE_THE_FIELD_DATA_SUCCESS";
export const UPDATE_THE_FIELD_DATA_FAILURE = "UPDATE_THE_FIELD_DATA_FAILURE";

export const REVERT_TO_ORIGINAL_FIELD_DATA = "REVERT_TO_ORIGINAL_FIELD_DATA";
export const REVERT_TO_ORIGINAL_FIELD_DATA_SUCCESS = "REVERT_TO_ORIGINAL_FIELD_DATA_SUCCESS";
export const REVERT_TO_ORIGINAL_FIELD_DATA_FAILURE = "REVERT_TO_ORIGINAL_FIELD_DATA_FAILURE";

export const GET_LOAN_FIELD_DOCUMENTS = "GET_LOAN_FIELD_DOCUMENTS";
export const GET_LOAN_FIELD_DOCUMENTS_SUCCESS = "GET_LOAN_FIELD_DOCUMENTS_SUCCESS";
export const GET_LOAN_FIELD_DOCUMENTS_FAILURE = "GET_LOAN_FIELD_DOCUMENTS_FAILURE";

export const loadDataExtractionHIL = createAction(
  LOAD_DATA_EXTRACTION_HIL,
  props<{ projectId: number; loanId: string }>()
);

export const loadDataExtractionHILSuccess = createAction(
  LOAD_DATA_EXTRACTION_HIL_SUCCESS,
  props<{ data: DataExtractionHIL }>()
);

export const loadDataExtractionHILFailure = createAction(LOAD_DATA_EXTRACTION_HIL_FAILURE, props<{ error: string }>());

export const addLoanDataExtraction = createAction(
  ADD_LOAN_DATA_EXTRACTION,
  props<{ projectId: number; loanId: string; addLoanDataExtraction: AddLoanDataExtraction }>()
);

export const addLoanDataExtractionSuccess = createAction(
  ADD_LOAN_DATA_EXTRACTION_SUCCESS,
  props<{ success: boolean }>()
);

export const addLoanDataExtractionFailure = createAction(ADD_LOAN_DATA_EXTRACTION_FAILURE, props<{ error: string }>());

export const updateLoanDataExtraction = createAction(
  UPDATE_LOAN_DATA_EXTRACTION,
  props<{
    projectId: number;
    loanId: string;
    fieldId: string;
    updateLoanDataExtraction: UpdateLoanDataExtraction;
  }>()
);

export const updateLoanDataExtractionSuccess = createAction(
  UPDATE_LOAN_DATA_EXTRACTION_SUCCESS,
  props<{ success: boolean }>()
);

export const updateLoanDataExtractionFailure = createAction(
  UPDATE_LOAN_DATA_EXTRACTION_FAILURE,
  props<{ error: string }>()
);

export const GetDocumentByDocumentId = createAction(
  GET_DOCUMENT_BY_DOCUMENT_ID,
  props<{ documentId: string; projectId: number; loanId: string; freshfetch?: boolean }>()
);

export const GetDocumentByDocumentIdSuccess = createAction(
  GET_DOCUMENT_BY_DOCUMENT_ID_SUCCESS,
  props<{ documentId: string; data: DocumentContentResponse }>()
);

export const GetDocumentByDocumentIdFailure = createAction(
  GET_DOCUMENT_BY_DOCUMENT_ID_FAILURE,
  props<{ documentId: string; errors?: string[]; message: string }>()
);

export const DeleteRestoreFieldValue = createAction(
  DELETE_RESTORE_FIELD_VALUE,
  props<{
    projectId: number;
    loanId: string;
    fieldValueId: string | undefined;
    deleteRestoreFlag: DeleteRestoreFlagFieldValue;
  }>()
);

export const DeleteRestoreFieldValueSuccess = createAction(
  DELETE_RESTORE_FIELD_VALUE_SUCCESS,
  props<{ success: boolean }>()
);

export const DeleteRestoreFieldValueFailure = createAction(
  DELETE_RESTORE_FIELD_VALUE_FAILURE,
  props<{ error?: string }>()
);

export const UpdateIsTruth = createAction(
  UPDATE_IS_TRUTH,
  props<{ fieldValueId: string; projectId: number; loanId: string }>()
);

export const UpdateIsTruthSuccess = createAction(UPDATE_IS_TRUTH_SUCCESS, props<{ success: boolean }>());

export const UpdateIsTruthFailure = createAction(UPDATE_IS_TRUTH_FAILURE, props<{ error?: string }>());

export const fetchLoanFields = createAction(
  FETCH_LOAN_FIELDS,
  props<{
    projectId: number;
    loanId: string;
    fetchFields: FetchField;
  }>()
);

export const fetchLoanFieldsSuccess = createAction(FETCH_LOAN_FIELDS_SUCCESS, props<{ response: GetFieldList[] }>());

export const fetchLoanFieldsFailure = createAction(
  FETCH_LOAN_FIELDS_FAILURE,
  props<{
    error?: string;
  }>()
);

export const getEntityList = createAction(
  GET_ENTITY_LIST,
  props<{ projectId: number; loanId: string; fieldId: string }>()
);

export const getEntityListSuccess = createAction(GET_ENTITY_LIST_SUCCESS, props<{ response: GetEntityList[] }>());

export const getEntityListFailure = createAction(GET_ENTITY_LIST_FAILURE, props<{ error: string }>());

export const loadDocumentFields = createAction(
  "LOAN_DOCUMENT_FIELDS",
  props<{ documentTypeCode: string; projectId: number }>()
);

export const loadDocumentFieldsSuccess = createAction(
  "LOAN_DOCUMENT_FIELDS_SUCESS",
  props<{ data: DocumentTypeResponse }>()
);

export const loadDocumentFieldsFailure = createAction("LOAN_DOCUMENT_FIELDS_FAILURE", props<{ error: string }>());

export const updateTheFieldData = createAction(
  UPDATE_THE_FIELD_DATA,
  props<{
    projectId: number;
    loanId: string;
    fieldValueId: string;
    updateTheFieldValue: string | null | undefined;
  }>()
);

export const updateTheFieldDataSuccess = createAction(UPDATE_THE_FIELD_DATA_SUCCESS, props<{ success: boolean }>());

export const updateTheFieldDataFailure = createAction(UPDATE_THE_FIELD_DATA_FAILURE, props<{ error: string }>());

export const revertToOriginalFieldData = createAction(
  REVERT_TO_ORIGINAL_FIELD_DATA,
  props<{
    projectId: number;
    loanId: string;
    fieldValueId: string;
  }>()
);

export const revertToOriginalFieldDataSuccess = createAction(
  REVERT_TO_ORIGINAL_FIELD_DATA_SUCCESS,
  props<{ success: boolean }>()
);

export const revertToOriginalFieldDataFailure = createAction(
  REVERT_TO_ORIGINAL_FIELD_DATA_FAILURE,
  props<{ error: string }>()
);

export const getDocuments = createAction(
  GET_LOAN_FIELD_DOCUMENTS,
  props<{
    projectId: number;
    loanId: string;
    fieldId: string;
    requestBody: IParentDocument;
  }>()
);

export const getDocumentsSuccess = createAction(
  GET_LOAN_FIELD_DOCUMENTS_SUCCESS,
  props<{ response: IDocumentType[] }>()
);

export const getDocumentsFailure = createAction(GET_LOAN_FIELD_DOCUMENTS_FAILURE, props<{ error: string }>());

export type DataExtractionHilActions =
  | ActionType<typeof loadDataExtractionHIL>
  | ActionType<typeof loadDataExtractionHILSuccess>
  | ActionType<typeof loadDataExtractionHILFailure>
  | ActionType<typeof addLoanDataExtraction>
  | ActionType<typeof addLoanDataExtractionSuccess>
  | ActionType<typeof addLoanDataExtractionFailure>
  | ActionType<typeof updateLoanDataExtraction>
  | ActionType<typeof updateLoanDataExtractionSuccess>
  | ActionType<typeof updateLoanDataExtractionFailure>
  | ActionType<typeof DeleteRestoreFieldValue>
  | ActionType<typeof DeleteRestoreFieldValueSuccess>
  | ActionType<typeof DeleteRestoreFieldValueFailure>
  | ActionType<typeof fetchLoanFields>
  | ActionType<typeof fetchLoanFieldsSuccess>
  | ActionType<typeof fetchLoanFieldsFailure>
  | ActionType<typeof getEntityList>
  | ActionType<typeof getEntityListSuccess>
  | ActionType<typeof getEntityListFailure>
  | ActionType<typeof getDocuments>
  | ActionType<typeof getDocumentsSuccess>
  | ActionType<typeof getDocumentsFailure>
  | ActionType<typeof loadDocumentFields>
  | ActionType<typeof loadDocumentFieldsSuccess>
  | ActionType<typeof loadDocumentFieldsFailure>
  | ActionType<typeof GetDocumentByDocumentId>
  | ActionType<typeof GetDocumentByDocumentIdSuccess>
  | ActionType<typeof GetDocumentByDocumentIdFailure>
  | ActionType<typeof UpdateIsTruth>
  | ActionType<typeof UpdateIsTruthSuccess>
  | ActionType<typeof UpdateIsTruthFailure>
  | ActionType<typeof updateTheFieldData>
  | ActionType<typeof updateTheFieldDataSuccess>
  | ActionType<typeof updateTheFieldDataFailure>
  | ActionType<typeof revertToOriginalFieldData>
  | ActionType<typeof revertToOriginalFieldDataSuccess>
  | ActionType<typeof revertToOriginalFieldDataFailure>;
