import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DataExtractionHILStoreState } from "./data-extraction-hil.types";

const dataExtractionHILStoreState = createFeatureSelector<DataExtractionHILStoreState>("data_extraction_hil_feature");

export const selectDataExtractionHIL = createSelector(
  dataExtractionHILStoreState,
  (state: DataExtractionHILStoreState) => state.dataExtractionHIL
);

export const updatedDataExtractionSelector = createSelector(
  dataExtractionHILStoreState,
  (state: DataExtractionHILStoreState) => state.updatedDataExtraction
);

export const selectAddLoanDataExtractionSuccess = createSelector(
  dataExtractionHILStoreState,
  (state: DataExtractionHILStoreState) => state.addLoanDataExtractionSuccess
);

export const getDocumentByIdSelector = (documentId: string) =>
  createSelector(dataExtractionHILStoreState, (state: DataExtractionHILStoreState) => state.document.data[documentId]);

export const updateIsTrustSelector = createSelector(
  dataExtractionHILStoreState,
  (state: DataExtractionHILStoreState) => state.updateIsTruthSuccess
);

export const selectDeleteRestoreFieldValueResponse = createSelector(
  dataExtractionHILStoreState,
  (state: DataExtractionHILStoreState) => state.deleteRestoreFieldValueResponse
);

export const updateTheFieldDataSelector = createSelector(
  dataExtractionHILStoreState,
  (state: DataExtractionHILStoreState) => state.updateTheFieldData
);

export const revertToOriginalFieldDataSelector = createSelector(
  dataExtractionHILStoreState,
  (state: DataExtractionHILStoreState) => state.revertToOriginalFieldValue
);

export const selectFetchFieldResponse = createSelector(
  dataExtractionHILStoreState,
  (state: DataExtractionHILStoreState) => state.loanFieldsResponse?.data || []
);
export const selectFetchFieldResponseFailure = createSelector(
  dataExtractionHILStoreState,
  (state: DataExtractionHILStoreState) => state.loanFieldsResponse?.errorMessage
);
export const selectEntityListResponse = createSelector(
  dataExtractionHILStoreState,
  (state: DataExtractionHILStoreState) => state.entityListResponse?.data || []
);
export const selectEntityListResponseFailure = createSelector(
  dataExtractionHILStoreState,
  (state: DataExtractionHILStoreState) => state.entityListResponse?.errorMessage
);
export const selectDocumentData = createSelector(
  dataExtractionHILStoreState,
  (state) => state.documentData?.data || []
);

export const selectDocumentFailure = createSelector(
  dataExtractionHILStoreState,
  (state: DataExtractionHILStoreState) => state.documentData?.errorMessage
);
